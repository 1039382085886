import type { ReactElement } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import type { ProductFindInStore as ProductFindInStoreSchema } from '../../../../../amplienceTypes/schemas/imported/product-find-in-store-schema';
import { Typography } from '../../../../shared/core/typography/Typography';
import { colors } from '../../../../shared/core/styles';
import type { StockLocatorOverlayProps } from './StockLocatorOverlay';
import type { ProductStockLocator } from '../../../../../amplienceTypes/schemas/imported/product-stock-locator-schema';
import { useHashChanged } from '../../../../../utilities/hooks';
import { useStaticContext } from '../../../../../utilities/context/static/StaticContext';

const StockLocatorOverlay = dynamic<StockLocatorOverlayProps & ProductStockLocator>(
  () => import('./StockLocatorOverlay').then(mod => mod.StockLocatorOverlay),
  { ssr: false }
);

const S = {
  FindInStore: styled.div<{ $isHidden: boolean }>`
    padding: 4px 0 20px;
    display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
    align-items: center;
    gap: 5px;
  `,

  Label: styled(Typography)`
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: ${colors.BLUE_CHARCOAL};
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    text-decoration: underline;

    &::before {
      content: '\\F1BE';
      font-family: gstar-icons;
      font-style: normal;
      font-size: 12px;
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 4px;
      color: ${colors.NERO_GREY};
    }
  `,
};

type FindInStoreProps = ProductFindInStoreSchema & {
  isHidden: boolean;
  hasLocalStoreStock: boolean;
};

export const FindInStore = ({
  findInStoreLinkV2,
  availabilityLinkV2,
  stockLocator,
  isHidden,
  hasLocalStoreStock,
}: FindInStoreProps): ReactElement => {
  const { t } = useTranslation('pdp', { keyPrefix: 'productFindInStore' });
  const {
    configuration: { siteHasPhysicalStores },
  } = useStaticContext();
  const { hashMatched, setHashMatched } = useHashChanged(['#stock-locator']);

  return siteHasPhysicalStores ? (
    <>
      <S.FindInStore $isHidden={isHidden as boolean}>
        <S.Label
          component="span"
          variant="title"
          color={colors.PRUSSIAN_BLUE}
          onClick={() => setHashMatched(true)}
          testId={hasLocalStoreStock ? 'available-pickup-today' : 'local-store-stock-link'}
          data-cs-capture=""
        >
          {hasLocalStoreStock
            ? availabilityLinkV2 || t('availabilityLink')
            : findInStoreLinkV2 || t('findInStoreLink')}
        </S.Label>
      </S.FindInStore>
      {hashMatched && stockLocator && (
        <StockLocatorOverlay onClose={() => setHashMatched(false)} {...stockLocator.content} />
      )}
    </>
  ) : (
    <></>
  );
};
